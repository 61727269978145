<template>
  <ValidationForm v-slot:default="{ handleSubmit }">
    <RecoveryLayout>
      <template v-slot:auth-inputs>
        <ValidationInputField
          type="tel"
          validate-name="телефон"
          label="Телефон"
          v-model="phone"
          mask="+7 (9##) ###-##-##"
          required
          rules="required|phone"
          style="margin-bottom: 30px"
        />
        <div class="">
          <p
            v-if="phone.length < 18"
            class="authoriz__text-input authoriz__text-input-recovery"
          >
            Введите номер в формате:
            <span v-if="phone.length < 18" style="font-weight: 600"
              >+7 (XXX) XXX-XX-XX</span
            >
            для получение потверждающего звонка.
          </p>
          <p v-else class="authoriz__text-input authoriz__text-input-recovery">
            На номер телефона:
            <span v-if="phone.length < 18" style="font-weight: 600"
              >+7 (XXX) XXX-XX-XX</span
            >
            <span v-else style="font-weight: 600">{{ phone }}</span> сейчас
            поступит СМС. Введите последние 4 цифры.
          </p>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          type="submit"
          @click="handleSubmit(startRecovery)"
          class="btn btn_color_green flex-right authoriz__btn"
          :disabled="phone.length < 18"
        >
          Далее
        </v-btn>
      </template>
    </RecoveryLayout>
  </ValidationForm>
</template>

<script>
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
import RecoveryLayout from "@/components/Auths/Recovery/RecoveryLayout";
export default {
  name: "RecoveryStartForm",
  components: { RecoveryLayout, ValidationForm, ValidationInputField },
  computed: {
    phone: {
      get() {
        return this.$store.getters.getRecoveryForm.phone.value;
      },
      set(newValue) {
        this.$store.commit("setFormValue", {
          formName: "recoveryForm",
          field: "phone",
          value: newValue
        });
      }
    }
  },
  methods: {
    startRecovery() {
      this.$store.dispatch("startRecovery").catch(e => {
        if (e?.response?.status == 401) {
          this.$store.commit("SET_ERROR_NOTIFICATION", {
            errors: [
              {
                field_verbose: "Ошибка",
                message: "В системе данный номер не зарегистрирован!"
              }
            ]
          });
        }
      });
    }
  },
  created() {
    if (this.$store.getters.getLoginForm.phone.value) {
      this.$store.commit("setFormValue", {
        formName: "recoveryForm",
        field: "phone",
        value: this.$store.getters.getLoginForm.phone.value
      });
    } else {
      this.$store.commit("setFormValue", {
        formName: "recoveryForm",
        field: "phone",
        value: this.$store.getters.getRecoveryForm.phone.value
      });
    }
  }
};
</script>

<style scoped>
.authoriz__item.authoriz__item-inputs {
  margin: 50px;
}
</style>
